import React, { useState } from 'react';
import { Link, NavigationItem, Image } from 'components';
import classNames from 'classnames';
import { ReactComponent as Caret } from 'images/grey-caret.svg';
import * as styles from './styles.module.scss';

const NavigationItems = (data) => {
  const { data: item } = data;
  // state
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [mobileDropdownHidden, setMobileDropdownHidden] = useState(true);
  const [reduceOpacity, setReduceOpacity] = useState(false);

  let hasAdditional;
  const dropdown = [];

  if (item.dropdown) {
    for (const name in item.dropdown) {
      hasAdditional = name === 'additional';
      dropdown.push({
        name,
        children: item.dropdown[name],
      });
    }
  }

  // functions
  const handleDropdownClick = (e) => {
    if (window.innerWidth <= 1088) {
      setDropdownOpen(!dropdownOpen);
    }
    if (window.innerWidth > 1088) {
      if (e.keyCode === 13 || e.keyCode === 32) {
        setDropdownOpen(!dropdownOpen);
      } else {
        document.body.classList.remove('navOpen');
      }
    }
  };

  const handleMouseEnter = () => {
    if (window.innerWidth > 1088) {
      document.body.classList.add('navOpen');
      setDropdownOpen(!dropdownOpen);
    }
  };

  const handleMouseLeave = () => {
    if (window.innerWidth > 1088) {
      document.body.classList.remove('navOpen');
      setDropdownOpen(!dropdownOpen);
    }
  };

  const toggleOpacity = () => {
    setReduceOpacity(!reduceOpacity);
  };

  // For Keyboard navigation
  const handleFocusOut = (e) => {
    // find last item in category list
    const linkGroup = document.querySelector('[class*=categoryIsOpen] .category-links');
    if (linkGroup) {
      const linkList = linkGroup.querySelectorAll('.link-list');
      const lastGroup = linkList[linkList.length - 1];
      const lastGroupLinks = lastGroup.querySelectorAll('.header-nav-item');
      const lastLink = lastGroupLinks[lastGroupLinks.length - 1];
      // if last item in category matches with focus out item
      if (lastLink.href === e.target.href) {
        setDropdownOpen(!dropdownOpen);
      }
    }
  };

  const toggleMobileDropdown = classNames({
    [styles.categoryIsOpen]: dropdownOpen,
    [styles.noGradient]: !hasAdditional,
  });

  const mobileCaret = classNames([styles.caret], {
    [styles.caretOpen]: dropdownOpen,
  });

  const dropdownOpenClass = classNames('main-nav-category', {
    [styles.categoryIsOpen]: dropdownOpen,
    linkIsHovered: reduceOpacity,
  });

  const menuSlideIn = classNames({
    [styles.menuSlideIn]: dropdownOpen,
  });

  let twoColMenu = false;
  const colLength = dropdown.length / 2;
  if (dropdown.length > 6) {
    twoColMenu = true;
  }

  return (
    <>
      {/* HAS DROPDOWN LINKS */}
      {item.dropdown ? (
        <div
          id={`${item.name.replace(' ', '')}MenuButton`}
          aria-expanded={dropdownOpen}
          aria-haspopup="true"
          aria-controls={`${item.name.replace(' ', '')}Menu`}
          role="tab"
          tabIndex="0"
          onKeyDown={handleDropdownClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleDropdownClick}
          onBlur={handleFocusOut}
          className={`${styles.navCategory} ${dropdownOpenClass}`}
        >
          <div
            className={classNames([styles.categoryButtonContainer], {
              [styles.categoryButtonOpen]: dropdownOpen,
            })}
          >
            <button tabIndex="-1" className={styles.categoryButton} type="button">
              <span className={styles.categoryName}>{item.name}</span>
              <span className={mobileCaret}>
                <Caret />
              </span>
            </button>
          </div>
          <div
            role="menu"
            id={`${item.name.replace(' ', '')}Menu`}
            className={`${styles.linksGroup} linksGroup ${toggleMobileDropdown}`}
            aria-labelledby={`${item.name.replace(' ', '')}MenuButton`}
          >
            <div className={styles.linksGroupInner}>
              <div className={`${styles.categoryGroup} ${menuSlideIn} category-links`}>
                <div className={styles.categroyHeader}>{item.name}</div>
                {twoColMenu && (
                  <div className={styles.menuContainer}>
                    <div>
                      {dropdown.map((list, i) => {
                        const {
                          children: menuItem
                        } = list;
                        const genKey = `${item.key}-${menuItem.name.toLowerCase().replace(' ', '-')}`;

                        if (colLength > i) {
                          return (
                            <div className={`${styles.linkList} link-list`} key={genKey}>
                              <NavigationItem
                                key={menuItem.name.text}
                                link={menuItem}
                                reduceOpacity={reduceOpacity}
                                toggleOpacity={toggleOpacity}
                              />
                            </div>
                          );
                        }
                      })}
                    </div>
                    <div>
                      {dropdown.map((list, i) => {
                        const {
                          children: menuItem
                        } = list;
                        const genKey = `${item.key}-${menuItem.name.toLowerCase().replace(' ', '-')}`;

                        if (colLength < i) {
                          return (
                            <div className={`${styles.linkList} link-list`} key={genKey}>
                              <NavigationItem
                                key={menuItem.name.text}
                                link={menuItem}
                                reduceOpacity={reduceOpacity}
                                toggleOpacity={toggleOpacity}
                              />
                            </div>
                          );
                        }
                      })}
                    </div>
                  </div>
                )}
                {!twoColMenu && (
                  <>
                    {dropdown.map((list) => {
                      const {
                        children: menuItem
                      } = list;
                      const genKey = `${item.key}-${menuItem.name.toLowerCase().replace(' ', '-')}`;

                      return (
                        <div className={`${styles.linkList} link-list`} key={genKey}>
                          <NavigationItem
                            key={menuItem.name.text}
                            link={menuItem}
                            reduceOpacity={reduceOpacity}
                            toggleOpacity={toggleOpacity}
                          />
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        // DOESN'T HAVE DROP DOWN LINKS
        <div className={styles.navCategory}>
          <Link ldExternal key={item.url} className={styles.categoryButton} to={item.url} tabIndex="0">
            <span className="main-nav-category-container">
              <span className="main-nav-category-name">{item.name}</span>
            </span>
          </Link>
        </div>
      )}
    </>
  );
};

export default NavigationItems;
