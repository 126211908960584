// extracted by mini-css-extract-plugin
export var animateScrollIn = "styles-module--animateScrollIn--13028";
export var animateScrollOut = "styles-module--animateScrollOut--f0c06";
export var colorSequence = "styles-module--colorSequence--28ec4";
export var container = "styles-module--container--b88ba";
export var diagonalFadeIn = "styles-module--diagonalFadeIn--4ba46";
export var grow = "styles-module--grow--0b436";
export var growAndShrink = "styles-module--growAndShrink--ada15";
export var growAndShrinkSubtle = "styles-module--growAndShrinkSubtle--dc5d4";
export var moveBg = "styles-module--move-bg--46c8a";
export var rotateLoop = "styles-module--rotateLoop--421ad";
export var spin = "styles-module--spin--32938";
export var spinCounter = "styles-module--spinCounter--b3990";