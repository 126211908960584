// extracted by mini-css-extract-plugin
export var altGridTop = "styles-module--altGridTop--a5fd1";
export var animateScrollIn = "styles-module--animateScrollIn--88378";
export var animateScrollOut = "styles-module--animateScrollOut--5849c";
export var colorSequence = "styles-module--colorSequence--5d2b3";
export var diagonalFadeIn = "styles-module--diagonalFadeIn--93712";
export var gridTop = "styles-module--gridTop--ded5c";
export var grow = "styles-module--grow--3e2dd";
export var growAndShrink = "styles-module--growAndShrink--16cfa";
export var growAndShrinkSubtle = "styles-module--growAndShrinkSubtle--5a353";
export var moveBg = "styles-module--move-bg--a5337";
export var rotateLoop = "styles-module--rotateLoop--31345";
export var spin = "styles-module--spin--70a30";
export var spinCounter = "styles-module--spinCounter--369de";