// extracted by mini-css-extract-plugin
export var animateScrollIn = "styles-module--animateScrollIn--21477";
export var animateScrollOut = "styles-module--animateScrollOut--08c72";
export var colorSequence = "styles-module--colorSequence--95e43";
export var diagonalFadeIn = "styles-module--diagonalFadeIn--30613";
export var grow = "styles-module--grow--2a600";
export var growAndShrink = "styles-module--growAndShrink--265cc";
export var growAndShrinkSubtle = "styles-module--growAndShrinkSubtle--ab363";
export var moveBg = "styles-module--move-bg--fcb3b";
export var particlesSVG = "styles-module--particlesSVG--e57a3";
export var rotateLoop = "styles-module--rotateLoop--2b05a";
export var spin = "styles-module--spin--7e17b";
export var spinCounter = "styles-module--spinCounter--b6913";