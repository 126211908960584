import React from 'react';
import { Section, Image, Link } from 'components';
import { ReactComponent as RedBubble } from 'images/card-grid/red-bubble-new.svg';

import * as styles from './styles.module.scss';

const CardGrid = ({ data }) => {
  const {
    primary: { use_two_column_layout: isTwoColLayout, add_bubble_background: addBubble, title, content },
    items,
  } = data;

  const titleText = title?.text;
  const contentText = content?.text;
  const isTwoColLayoutClass = isTwoColLayout ? styles.twoColLayout : '';

  return (
    <Section sliceName="CardGrid">
      <div className={`${styles.cardGrid} ${isTwoColLayoutClass}`}>
        {addBubble && (
          <RedBubble className={styles.redBubble} />
        )}
        {titleText && (
          <div className={styles.titleContainer}>
            <h2>{titleText}</h2>
            {contentText && (
              <p>{contentText}</p>
            )}
          </div>
        )}
        <div className={styles.cardContainer}>
          {items.map((card) => {
            const {
              use_one_line_icon: useOneLineIcon,
              icon,
              title: cardTitle,
              content: cardContent,
              card_link_url: linkUrl,
            } = card;
            const cardTitleText = cardTitle?.text;
            const cardContentHtml = cardContent?.html;
            const cardLinkUrl = linkUrl?.url;
            const oneLineIconClass = useOneLineIcon ? styles.oneLineIcon : '';

            return (
              <Link key={cardTitleText} className={`${styles.cardLink} ${oneLineIconClass}`} to={cardLinkUrl}>
                <div className={styles.card}>
                  <div className={styles.cardTitle}>
                    <Image className={styles.icon} image={icon} />
                    <h4>{cardTitleText}</h4>
                  </div>
                  <div className={styles.cardContent} dangerouslySetInnerHTML={{ __html: cardContentHtml }} />
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </Section>
  );
};

export default CardGrid;
