import React from 'react';
import { Section, Link, Image, Particles } from 'components';

import * as styles from './styles.module.scss';

const BreakoutQuote = (props) => {
  const {
    avatar,
    large_content: content,
    cta_button_url: ctaLink,
    cta_button_text: ctaText,
    content_below_avatar: quote,
    link_text: linkText,
    link_url: linkUrl,
    className,
  } = props;

  // content checks
  const contentHtml = content?.html;
  const quoteHtml = quote?.html;
  const hasImage = avatar?.url;
  const linkUrlLink = linkUrl?.url;
  const ctaLinkUrl = ctaLink?.url;
  const linkTextContent = linkText?.text;

  // default content
  const defaultContent = `<p>Discover how to deploy code faster with less risk.</p>`;
  const contentToUse = content?.text ? contentHtml : defaultContent;
  const showCtaButton = ctaText?.text.length > 0;

  return (
    <Section
      className={`breakout-quote-comp ${className || ''} ${styles.breakoutQuote}`}
      containerClassName={styles.container}
      sliceName="BreakoutQuote"
    >
      <div className={styles.quoteContainer}>
        {hasImage && (
          <div className={styles.avatarContainer}>
            <Image className={styles.avatar} image={avatar} />
          </div>
        )}
        {quote?.text && <div dangerouslySetInnerHTML={{ __html: quoteHtml }} className={styles.quote} />}
        {linkText && (
          <Link withArrow to={linkUrlLink}>{linkTextContent}</Link>
        )}
      </div>
      <div className={styles.particlesContainer}>
        <Particles />
      </div>
      <div className={styles.contentContainer}>
        <div dangerouslySetInnerHTML={{ __html: contentToUse }} className={styles.content} />
        {showCtaButton && (
          <Link className={`button ${styles.ctaButton}`} to={ctaLinkUrl}>
            {ctaText?.text || 'Get a demo'}
          </Link>
        )}
      </div>
    </Section>
  );
};

export default BreakoutQuote;
