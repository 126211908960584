// extracted by mini-css-extract-plugin
export var animateScrollIn = "page-module--animateScrollIn--16117";
export var animateScrollOut = "page-module--animateScrollOut--59f9f";
export var colorSequence = "page-module--colorSequence--73ff8";
export var diagonalFadeIn = "page-module--diagonalFadeIn--7abe3";
export var grow = "page-module--grow--05068";
export var growAndShrink = "page-module--growAndShrink--a09ea";
export var growAndShrinkSubtle = "page-module--growAndShrinkSubtle--68d79";
export var headerContainer = "page-module--headerContainer--233ca";
export var innerContainer = "page-module--innerContainer--1f065";
export var leftCol = "page-module--leftCol--b08ad";
export var mainContent = "page-module--mainContent--d0c5f";
export var moveBg = "page-module--move-bg--56505";
export var nextArticle = "page-module--nextArticle--d472f";
export var nextPrevArticles = "page-module--nextPrevArticles--32a00";
export var pageMainContent = "page-module--pageMainContent--18fdd";
export var pagesContainer = "page-module--pagesContainer--03cbb";
export var prevArticle = "page-module--prevArticle--1143d";
export var rotateLoop = "page-module--rotateLoop--a0bf8";
export var sidebarNav = "page-module--sidebarNav--5377e";
export var spin = "page-module--spin--f07a7";
export var spinCounter = "page-module--spinCounter--a6534";