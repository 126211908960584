const generatePath = require('../../config/generatePath');
const slugify = require('../../config/slugify');

module.exports = (doc) => {
  if (doc.uid === 'home') {
    return `/`;
  }
  // if (doc.type === 'case_studies') {
  //   return `/case-studies/${doc.uid}/`;
  // }
  return generatePath(doc.uid);
}
