module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: 'Feature Flag & Toggle Management | FeatureFlags', // Default Site Title used for SEO & PWA
  description:
    'LaunchDarkly provides simple, scalable feature flag & toggle management (feature management) for the modern enterprise. Eliminate risk, deliver value.', // Default Site Decription used for SEO
  siteName: 'FeatureFlags', // Sitename for Facebook
  siteLanguage: 'en', // Language Tag on <html> element
  banner: '/open-graph.png', // Default OpenGraph image
  ogLanguage: 'en_AU', // Facebook Language

  // JSONLD / Manifest
  icon: 'src/images/icon.png', // Used for manifest favicon, splash screen, and icon generation
  shortName: 'LaunchDarkly', // shortname for manifest. MUST be shorter than 12 characters
  author: 'LaunchDarkly', // Author for schemaORGJSONLD
  themeColor: '#405BFF', // PWA Icon background & address bar colour if installed on desktop
  backgroundColor: '#191919', // PWA colour shown before styles and content loads, should match the background-color CSS property in the site’s stylesheet

  twitter: '', // Twitter Username
};
