import React, { useEffect } from 'react';

const NeverBounce = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cdn.neverbounce.com/widget/dist/NeverBounce.js';

    window._NBSettings = {
      apiKey: 'public_310bf86df5c629f9b7072e5cc6c926e7',
      blockFreemail: false,
      blockRoleAccount: true,
      blockThrottledAttempts: true,
      displayPoweredBy: false,
      feedback: true,
      feedbackClass: 'nb-feedback',
      inputLatency: 999,
      rejectedMessage: 'invalid email',
      softRejectMessage: 'enter an email',
      freemailRejectMessage: 'enter a business email',
      throttleRejectMessage: 'too many attempts, try again later',
      timeout: 60,
    };
    alert('mooo');
    document.querySelector('body').appendChild(script);
  }, []);

  return <>script?</>;
};

export default NeverBounce;
