import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Link } from 'components';
import * as styles from './styles.module.scss';

const PageSidebarNav = () => {
  const sideNavData = useStaticQuery(graphql`
    query sideNavQuery {
      sideNav: prismicSettings {
        data {
          link_group_one {
            link_group_one_text {
              text
            }
            link_group_one_url {
              url
            }
          }
          link_group_one_title {
            text
          }
          link_group_two_title {
            text
          }
          link_group_two {
            link_group_two_text {
              text
            }
            link_group_two_url {
              url
            }
          }
        }
      }
    }
  `);

  const {
    sideNav: {
      data: {
        link_group_one: linkGroupOne,
        link_group_one_title: linkGroupOneTitle,
        link_group_two: linkGroupTwo,
        link_group_two_title: linkGroupTwoTitle,
      },
    },
  } = sideNavData;

  const linkGroupOneTitleText = linkGroupOneTitle?.text;
  const linkGroupTwoTitleText = linkGroupTwoTitle?.text;

  return (
    <div className={styles.sidebarNav}>
      <div className={styles.sidebarNavGroup}>
        <h6>{linkGroupOneTitleText}</h6>
        <div>
          {linkGroupOne.map((link) => {
            const { link_group_one_text: linkText, link_group_one_url: linkUrl } = link;

            const linkTextContent = linkText?.text;
            const linkUrlContent = linkUrl?.url;

            return (
              <div key={linkTextContent}>
                <Link to={linkUrlContent}>{linkTextContent}</Link>
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.sidebarNavGroup}>
        <h6>{linkGroupTwoTitleText}</h6>
        <div>
          {linkGroupTwo.map((link) => {
            const {
              link_group_two_text: linkText,
              link_group_two_url: linkUrl,
            } = link;

            const linkTextContent = linkText?.text;
            const linkUrlContent = linkUrl?.url;

            return (
              <div key={linkTextContent}>
                <Link to={linkUrlContent}>{linkTextContent}</Link>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
};

export default PageSidebarNav;
