import React, { useState } from 'react';
import { Link, Logo, NavigationItems } from 'components';
import { graphql, useStaticQuery } from 'gatsby';
import classNames from 'classnames';
import * as styles from './styles.module.scss';

const Navigation = ({ noNav }) => {
  const [isActive, setIsActive] = useState(false);

  const query = graphql`
    query HeaderNavHomepageQuery {
      prismicSettings {
        data {
          sub_links {
            link_text {
              text
            }
            link_url {
              url
            }
            parent_nav_item
          }
        }
      }
    }
  `;

  const data = useStaticQuery(query);

  const {
    prismicSettings: {
      data: { sub_links: subLinks },
    },
  } = data;

  // Building of subnav
  const navBarData = [
    { name: 'Feature Flag Guide', url: null, dropdown: [], key: 'fg' },
    { name: 'Libraries / SDKs', url: null, dropdown: [], key: 'ls' },
    { name: 'Terminology', url: null, dropdown: [], key: 'tm' },
    { name: 'Literature', url: null, key: 'lt' },
    { name: 'Blog', url: null, key: 'bl' },
  ];

  subLinks.forEach((sublink) => {
    const {
      parent_nav_item: parent,
      link_text: text,
      link_url: { url: linkUrl },
    } = sublink;

    const linkText = text?.text;

    navBarData.forEach((navItem) => {
      const { name, key } = navItem;
      if (name.toLowerCase() === parent.toLowerCase()) {
        if (key !== 'lt' && key !== 'bl') {
          navItem.dropdown.push({
            name: linkText,
            url: linkUrl,
          });
        } else {
          navItem.url = linkUrl;
        }
      }
    });
  });

  // functions
  const handleNavDropdown = () => {
    setIsActive(!isActive);
  };

  const handleBurgerClick = () => {
    setIsActive(!isActive);
  };

  const handleBurgerKey = (e) => {
    if (e.keyCode === 13 || e.keyCode === 32) {
      setIsActive(!isActive);
    }
  };

  const mainNav = classNames('main-nav-container', {
    [styles.mainNavOpen]: isActive,
  });

  const handleDemoLinkClick = () => {
    // track('Request Demo Button Clicked')
  };

  return (
    <nav className={styles.nav}>
      <div className={`${styles.navInner} container`}>
        <div className={styles.brand}>
          <Logo />
          {!noNav && (
            <div
              role="button"
              tabIndex="0"
              className={classNames([styles.mobileBurger], {
                [styles.burgerOpen]: isActive,
              })}
              data-target="headerNav"
              onClick={handleBurgerClick}
              onKeyDown={handleBurgerKey}
            >
              <span />
              <span />
            </div>
          )}
        </div>

        {!noNav && (
          <div className={`${styles.mainNavGroup} ${mainNav}`} id="mainNavigation">
            <div role="tablist" className={styles.mainNav}>
              {navBarData.map((link) => (
                <NavigationItems key={link.name} data={link} />
              ))}
            </div>
            <div className={styles.cta}>
              <a className="button" href="https://launchdarkly.com/">
                Learn more
              </a>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navigation;
