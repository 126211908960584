// extracted by mini-css-extract-plugin
export var animateScrollIn = "styles-module--animateScrollIn--2e68a";
export var animateScrollOut = "styles-module--animateScrollOut--3c962";
export var colorSequence = "styles-module--colorSequence--ab011";
export var diagonalFadeIn = "styles-module--diagonalFadeIn--8555e";
export var grow = "styles-module--grow--3477c";
export var growAndShrink = "styles-module--growAndShrink--66583";
export var growAndShrinkSubtle = "styles-module--growAndShrinkSubtle--3443a";
export var image = "styles-module--image--53a01";
export var moveBg = "styles-module--move-bg--ac522";
export var rotateLoop = "styles-module--rotateLoop--6d96a";
export var spin = "styles-module--spin--06e83";
export var spinCounter = "styles-module--spinCounter--e5d40";