import React from 'react';
import { Section } from 'components';

import * as styles from './styles.module.scss';

const HeroWithCenteredText = ({ data }) => {
  const {
    primary: { title, subtitle },
  } = data;

  const heroTitle = title?.text;
  const heroSubtitle = subtitle?.text;

  return (
    <Section sliceName="HeroWithCenteredText" className={`section ${styles.heroContainer}`}>
      <div className={styles.centerAlignContainer}>
        <h6>{heroSubtitle}</h6>
        <h1>{heroTitle}</h1>
      </div>
    </Section>
  );
};

export default HeroWithCenteredText;
