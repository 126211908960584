import React from 'react';
import { Link } from 'components';
import classNames from 'classnames';
import * as styles from './styles.module.scss';

const NavigationItem = (props) => {
  const {
    link: { name, url },
    reduceOpacity,
    toggleOpacity,
  } = props;
  const reducedOpacity = classNames(
    {
      'submenu-items-opacity-half': reduceOpacity,
    },
    'header-nav-item'
  );

  return (
    <Link
      key={name}
      className={`${reducedOpacity} ${styles.link}`}
      to={url}
      onMouseOver={toggleOpacity}
      onMouseOut={toggleOpacity}
      tabIndex="0"
      role="menuitem"
    >
      <span>
        <span className="header-nav-item-name header-nav-submenu-item-name">{name}</span>
      </span>
    </Link>
  );
};

export default NavigationItem;
