import React, { useState } from 'react';
import { ModalComponent, Image } from 'components';
import { ReactComponent as PlayIcon } from 'images/icons/icon-play-small.svg';
import * as styles from './styles.module.scss';

const VideoComponent = ({ data }) => {
  const { title, imageUrl, videoUrl } = data;

  const [modalOpen, setModalOpen] = useState(false);
  const toggleVideoModal = (e) => {
    e.preventDefault();
    setModalOpen(!modalOpen);
  };

  return (
    <div className="video-row">
      <div className="video-column">
        {imageUrl && <Image className="two-columns-video" image={imageUrl} />}
        <div className="video-overlay" onClick={toggleVideoModal} role="dialog">
          <div className="video-overlay-button">
            <div className="video-overlay-button-inner">
              <div className="video-overlay-button-inner-2">
                <div className="video-overlay-play-button" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div onClick={toggleVideoModal} className={styles.textColumn} role="button" tabIndex={-1}>
        <PlayIcon width="24" height="24" />
        {title}
      </div>
      <ModalComponent modalOpen={modalOpen} toggleModal={toggleVideoModal}>
        <div className={styles.modalContentVideo}>
          <div className={styles.videoSection}>
            <iframe
              title="LaunchDarkly testimonial by HoneyComb"
              src={videoUrl}
              width="100%"
              height="100%"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
            />
          </div>
        </div>
      </ModalComponent>
    </div>
  );
};

export default VideoComponent;
