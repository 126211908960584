import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Link } from 'components';
import { ReactComponent as Caret } from 'images/grey-caret.svg';
import * as styles from './styles.module.scss';

const PageLeftbarNav = ({ navGroup, location, pageTitle }) => {
  const nav = [];
  const [isNavOpen, setNavOpen] = useState(false);

  // query all pages
  const pageQuery = useStaticQuery(graphql`
    query pageQueries {
      page: allPrismicPage(filter: {lang: {eq: "en-us"}}) {
        nodes {
          data {
            page_title {
              text
            }
            menu_order
            page_nav_group
          }
          url
        }
      }
    }
  `);

  const sortMenu = (a, b) => {
    if (a.data.menu_order < b.data.menu_order) {
      return -1;
    }
    if (a.data.menu_order > b.data.menu_order) {
      return 1;
    }
    return 0;
  };

  const {
    page: { nodes: pages },
  } = pageQuery;

  pages.map((page) => {
    if (page.data.page_nav_group === navGroup) {
      nav.push(page);
    }
  });

  const sortedNav = nav.sort(sortMenu);

  const toggleMenu = () => {
    setNavOpen(!isNavOpen);
  };

  return (
    <div className={styles.leftbarNav}>
      <div onClick={toggleMenu} className={styles.mobileOpener}>
        <div className={styles.pageTitle}>{pageTitle}</div>
        <Caret className={`${styles.mobileCaret} ${isNavOpen ? styles.menuOpen : ''}`} />
      </div>
      <div className={`${styles.menu} ${isNavOpen ? styles.menuOpen : ''}`}>
        {sortedNav.map((navItem) => {
          const {
            data: { page_title: navTitle },
            url,
          } = navItem;

          const activeMenu = url === `${location.pathname}${location.pathname.endsWith('/') ? '' : '/'}` ? styles.activeMenu : '';
          return (
            <Link key={navTitle.text} className={activeMenu} to={url}>{ navTitle.text }</Link>
          );
        })}
      </div>
    </div>
  );
};

export default PageLeftbarNav;
