// extracted by mini-css-extract-plugin
export var animateScrollIn = "styles-module--animateScrollIn--82379";
export var animateScrollOut = "styles-module--animateScrollOut--f2462";
export var colorSequence = "styles-module--colorSequence--b91bf";
export var container = "styles-module--container--83ef3";
export var diagonalFadeIn = "styles-module--diagonalFadeIn--81834";
export var gridBottom = "styles-module--gridBottom--261c2";
export var grow = "styles-module--grow--03cf9";
export var growAndShrink = "styles-module--growAndShrink--e4ca0";
export var growAndShrinkSubtle = "styles-module--growAndShrinkSubtle--c3aff";
export var moveBg = "styles-module--move-bg--c9b9d";
export var rotateLoop = "styles-module--rotateLoop--f4e95";
export var spin = "styles-module--spin--8178e";
export var spinCounter = "styles-module--spinCounter--7fc8c";
export var topCenter = "styles-module--topCenter--1d735";
export var topLeftLarge = "styles-module--topLeftLarge--0b04a";
export var topLeftSmall = "styles-module--topLeftSmall--82d40";
export var topRight = "styles-module--topRight--15854";