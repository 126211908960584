// extracted by mini-css-extract-plugin
export var animateScrollIn = "styles-module--animateScrollIn--4cf98";
export var animateScrollOut = "styles-module--animateScrollOut--82304";
export var colorSequence = "styles-module--colorSequence--bec3c";
export var diagonalFadeIn = "styles-module--diagonalFadeIn--d5ded";
export var grow = "styles-module--grow--eeb49";
export var growAndShrink = "styles-module--growAndShrink--12fe1";
export var growAndShrinkSubtle = "styles-module--growAndShrinkSubtle--0bae2";
export var isLarge = "styles-module--isLarge--4dfe3";
export var modalClose = "styles-module--modalClose--0ef6c";
export var modalContentVideo = "styles-module--modalContentVideo--df8cd";
export var moveBg = "styles-module--move-bg--06c7d";
export var password = "styles-module--password--e3fe1";
export var rotateLoop = "styles-module--rotateLoop--39f00";
export var signUpLabel = "styles-module--signUpLabel--195e9";
export var spin = "styles-module--spin--16e74";
export var spinCounter = "styles-module--spinCounter--952b1";
export var textColumn = "styles-module--textColumn--76142";
export var videoSection = "styles-module--videoSection--4e501";