// extracted by mini-css-extract-plugin
export var animateScrollIn = "styles-module--animateScrollIn--9c229";
export var animateScrollOut = "styles-module--animateScrollOut--ef432";
export var colorSequence = "styles-module--colorSequence--d068b";
export var container = "styles-module--container--950fb";
export var diagonalFadeIn = "styles-module--diagonalFadeIn--6a8f2";
export var grow = "styles-module--grow--09366";
export var growAndShrink = "styles-module--growAndShrink--4ef05";
export var growAndShrinkSubtle = "styles-module--growAndShrinkSubtle--757d2";
export var moveBg = "styles-module--move-bg--a6fcd";
export var rotateLoop = "styles-module--rotateLoop--9b4c4";
export var spin = "styles-module--spin--62754";
export var spinCounter = "styles-module--spinCounter--7dd3e";
export var video = "styles-module--video--f8635";