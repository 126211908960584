// extracted by mini-css-extract-plugin
export var animateScrollIn = "styles-module--animateScrollIn--07474";
export var animateScrollOut = "styles-module--animateScrollOut--f0012";
export var colorSequence = "styles-module--colorSequence--86cb8";
export var diagonalFadeIn = "styles-module--diagonalFadeIn--7ca69";
export var grow = "styles-module--grow--0368d";
export var growAndShrink = "styles-module--growAndShrink--c81c2";
export var growAndShrinkSubtle = "styles-module--growAndShrinkSubtle--34ac5";
export var logo = "styles-module--logo--846d5";
export var moveBg = "styles-module--move-bg--7fec7";
export var rotateLoop = "styles-module--rotateLoop--c5b47";
export var spin = "styles-module--spin--ac37b";
export var spinCounter = "styles-module--spinCounter--82e21";