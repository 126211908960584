import React, { useState } from 'react';
import { ModalComponent, MarketoForm, Link } from 'components';
import * as styles from './styles.module.scss';

const MarketoModalButton = ({ data }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const { btnText, marketoId, modalTitle, description } = data;

  return (
    <div>
      <div className="button" onClick={toggleModal} data-target="signup" tabIndex="0">
        <span className="navbar-item-name">{btnText}</span>
      </div>
      <ModalComponent hideButton modalOpen={modalOpen} toggleModal={toggleModal}>
        <div className={styles.marketoFormBg}>
          <button
            className="ld-modal-close-button"
            type="button"
            aria-label="close"
            tabIndex="0"
            onClick={toggleModal}
          />
          <div className={styles.container}>
            <div>
              <h3 className={styles.title}>{modalTitle}</h3>
              {description && (
                <p>{description}</p>
              )}
            </div>
            <MarketoForm formId={marketoId} />
            <p className={styles.helpText}>
              By clicking "Start My Trial Now", you agree to LaunchDarkly's{' '}
              <Link to="https://launchdarkly.com/policies/terms-of-service/">Terms</Link> and{' '}
              <Link to="https://launchdarkly.com/policies/privacy/">Privacy Policies</Link>.
            </p>
          </div>
        </div>
      </ModalComponent>
    </div>
  );
};

export default MarketoModalButton;
