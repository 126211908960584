import React from 'react';
import { Footer, Header, SEO, NoJs, Grid, BannerComponent } from 'components';
import { ReactComponent as CenterGrid } from 'images/grid-bg-dip-top.svg';
import '../../../sass/global/styles.scss';
import * as styles from './styles.module.scss';
import './styles.scss';

const Layout = (props) => {
  const {
    children,
    mainClassName,
    customSEO,
    staticNavbar,
    noTopBg,
    noBottomBg,
    noFooter,
    noNav,
    altTopGrid,
    gridBottomClassName,
    backgroundGridType,
    seo,
  } = props;
  const showTop = !!(!altTopGrid && !noTopBg);
  const showBottom = !!noBottomBg;

  const backgroundType = backgroundGridType && backgroundGridType !== 'None' ? backgroundGridType : null;
  return (
    <>
      <NoJs />
      <Header staticNavbar={staticNavbar} noNav={noNav} />
      <main className={mainClassName || ''}>
        {backgroundType && <Grid backgroundGridType={backgroundType} />}

        {!backgroundGridType && showTop && <Grid className={styles.gridTop} />}
        {!backgroundGridType && altTopGrid && <CenterGrid className={styles.altGridTop} />}

        {!showBottom && (
          <Grid
            backgroundGridType="Bottom Grid"
            className={`grid-bottom ${styles.gridBottom} ${gridBottomClassName}`}
          />
        )}

        {!customSEO && <SEO {...seo} />}
        {children}
      </main>
      {!noFooter && <Footer />}
    </>
  );
};

export default Layout;
