// extracted by mini-css-extract-plugin
export var animateScrollIn = "styles-module--animateScrollIn--b2321";
export var animateScrollOut = "styles-module--animateScrollOut--3a97e";
export var colorSequence = "styles-module--colorSequence--64b6a";
export var copyright = "styles-module--copyright--ea37b";
export var diagonalFadeIn = "styles-module--diagonalFadeIn--9c2d4";
export var footerBottom = "styles-module--footerBottom--f16e0";
export var footerNote = "styles-module--footerNote--939dd";
export var footerTop = "styles-module--footerTop--ea25b";
export var grow = "styles-module--grow--cb1b0";
export var growAndShrink = "styles-module--growAndShrink--7567a";
export var growAndShrinkSubtle = "styles-module--growAndShrinkSubtle--e6e1d";
export var linkGroup = "styles-module--linkGroup--e5b37";
export var linkGroupsContainer = "styles-module--linkGroupsContainer--2bf00";
export var moveBg = "styles-module--move-bg--e6ccc";
export var rotateLoop = "styles-module--rotateLoop--d739e";
export var snsGroup = "styles-module--snsGroup--cbec1";
export var spin = "styles-module--spin--b1387";
export var spinCounter = "styles-module--spinCounter--dac82";