// extracted by mini-css-extract-plugin
export var animateScrollIn = "styles-module--animateScrollIn--719bc";
export var animateScrollOut = "styles-module--animateScrollOut--bb94b";
export var avatar = "styles-module--avatar--0c149";
export var avatarBorder = "styles-module--avatarBorder--2b1e5";
export var avatarContainer = "styles-module--avatarContainer--a2ba0";
export var breakoutQuote = "styles-module--breakoutQuote--f9fd1";
export var caseStudyBreakout = "styles-module--caseStudyBreakout--35837";
export var colorSequence = "styles-module--colorSequence--ba340";
export var company = "styles-module--company--6ae5f";
export var container = "styles-module--container--3cc3f";
export var content = "styles-module--content--a581b";
export var contentContainer = "styles-module--contentContainer--bf912";
export var ctaButton = "styles-module--ctaButton--e2c97";
export var diagonalFadeIn = "styles-module--diagonalFadeIn--88875";
export var grow = "styles-module--grow--5bdf0";
export var growAndShrink = "styles-module--growAndShrink--2d5de";
export var growAndShrinkSubtle = "styles-module--growAndShrinkSubtle--55d3d";
export var moveBg = "styles-module--move-bg--ba58e";
export var name = "styles-module--name--c3c5e";
export var particlesContainer = "styles-module--particlesContainer--16a41";
export var quote = "styles-module--quote--85eab";
export var quoteContainer = "styles-module--quoteContainer--0b095";
export var role = "styles-module--role--59981";
export var rotateLoop = "styles-module--rotateLoop--68786";
export var spin = "styles-module--spin--d541d";
export var spinCounter = "styles-module--spinCounter--13afe";