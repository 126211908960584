import React from 'react';
import loadable from '@loadable/component';
import HeroWithCenteredText from '../../../slices/HeroWithCenteredText';
import CardGrid from '../../../slices/CardGrid';

const Slice = (props) => {
  const { data } = props;
  const { slice_type: sliceType } = data;

  const isMediaSlice = sliceType === 'two_cols_text_media' ? 'two-cols-text-media' : '';

  // Handle null slice_type prop
  if (!sliceType) {
    console.error('Please pass a slice_type from your template to the Slice component');
    return null;
  }

  // Convert slice type from snake_case to TitleCase
  const sliceName = sliceType
    .split('_')
    .map((item) => item.charAt(0).toUpperCase() + item.substring(1))
    .join('');

  // Get the slice component
  let CustomSlice;
  if (sliceName !== 'HeroWithCenteredText' && sliceName !== 'CardGrid') {
    CustomSlice = loadable(() => import(`slices/${sliceName}`));
  } else if (sliceName === 'HeroWithCenteredText') {
    return <HeroWithCenteredText className={isMediaSlice} {...props} />;
  } else if (sliceName === 'CardGrid') {
    return <CardGrid className={isMediaSlice} {...props} />;
  }

  // Handle missing exported Slice
  if (!CustomSlice) {
    console.error(`Can't find Slice ${sliceName}, are you sure it exists in the slices directory?`);
  }

  // Return Slice
  return <CustomSlice className={isMediaSlice} {...props} />;
};

export default Slice;
