import React, { useState, useEffect } from 'react';
import loadable from '@loadable/component';
import { OnVisible } from 'components';
import * as styles from './styles.module.scss';

const Particles = ({ className = '', color }) => {
  const [mounted, setMounted] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setMounted(true);

    return () => setMounted(false);
  }, []);

  const particleVariants = {
    red: '#FF386B',
    yellow: '#EBFF38',
    blue: '#405BFF',
    pink: '#FF386B',
    cyan: '#3DD6F5',
    purple: '#A34FDE',
  };

  if (!mounted) return null;

  if (!isVisible) {
    return (
      <div className={`${className} ${styles.particlesSVG}`}>
        <OnVisible onChange={setIsVisible} />
      </div>
    );
  }

  const ParticlesSVG = loadable(() => import('../ParticleSvg'));

  return (
    <div className={`${className} ${styles.particlesSVG}`}>
      <OnVisible>
        <ParticlesSVG color={color ? particleVariants[color] : particleVariants.red} />
      </OnVisible>
    </div>
  );
};

export default Particles;
