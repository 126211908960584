import React, { useState, useEffect } from 'react';
import { Image } from 'components';
import * as styles from './styles.module.scss';

const LogoScrollWheel = ({ logos, delay = 0, speed = 2000 }) => {

  // State control
  const [animationStep, setAnimationStep] = useState(0);

  // useEffect control
  // Initial useEffect to stagger
  useEffect(() => {
    let timeout = null;
    timeout = setTimeout(() => {
      setAnimationStep((animationStep) => animationStep + 1);
    }, delay);
    return () => {
      clearTimeout(timeout)
    };
  }, []);

  // Rolling useEffect to continually animate
  useEffect(() => {
    let interval = null;
    interval = setInterval(() => {
      if (logos.length - 1 === animationStep) {
        setAnimationStep(0)
      } else {
        setAnimationStep((animationStep) => animationStep + 1);
      }
    }, speed);
    return () => {
      clearInterval(interval)
    };
  }, [animationStep]);

  const getHideStep = (animationStep) => {
    if (animationStep === 0) {
      return logos.length - 1;
    }
    return animationStep - 1;
  }

  return (
    <div className={styles.logoScrollWheel}>
      {logos.map((item, index) => {
        const { client_name: name, logo } = item;
        const nameText = name?.text;
        return (
          <div className={`
              ${styles.logo}
              ${index === animationStep && logos.length > 1 ? styles.animateIn : null}
              ${index === getHideStep(animationStep) && logos.length > 1 ? styles.animateOut : null}
              ${index === animationStep || index === getHideStep(animationStep) ? null : logos.length <= 1 ? null : styles.animateKeepHidden}
            `}
            key={nameText}
          >
            <Image image={logo} alt={nameText} className={styles.logo} />
          </div>
        );
      })}
    </div>
  )
}

export default LogoScrollWheel;
