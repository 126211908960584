import React from 'react';
import { Section, Link, MarketoModalButton, Image } from 'components';
import { ReactComponent as DebuggerExport } from 'images/products/debugger-export.svg';
import { ReactComponent as FlagEvaluation } from 'images/products/flag-evaluation-graph.svg';
import { ReactComponent as FlagSetting } from 'images/products/flag-setting.svg';
import { ReactComponent as PercentageRollout } from 'images/products/percentage-rollout.svg';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import * as styles from './styles.module.scss';

const HeaderContent = ({ title, content, tagline, ctas = {}, subtitle, className, logicProps = {} }) => {
  const { primaryText, primaryLink, secondaryText, secondaryLink } = ctas;
  const { enableProductBackground, openMarketoModal, modalTitle, jumpToAnchor, bottomImage, narrowImage } = logicProps;

  // formatting
  const titleHtml = title?.html;
  const contentHtml = content?.html;
  const subtitleHtml = subtitle?.html;
  const taglineHtml = tagline?.html;
  const hasPrimary = primaryText?.text && primaryLink?.url;
  const hasSecondary = secondaryText?.text && secondaryLink?.url;
  // logic
  const openMarketoModalText = openMarketoModal?.text;
  const jumpToAnchorText = jumpToAnchor?.text;
  const showModalButton = !!openMarketoModalText && !jumpToAnchorText;
  const buttonData = {
    btnText: primaryText?.text,
    marketoId: openMarketoModalText,
    modalTitle: modalTitle?.text,
  };
  const narrowImageClass = narrowImage ? styles.narrowImage : '';
  const isBottomImageAvailable = bottomImage && bottomImage.localFile;
  // functions
  const jumpToAnchorOnClick = (e) => {
    e.preventDefault();
    const el = e.currentTarget;
    scrollIntoView(document.querySelector(`#${jumpToAnchorText}`), {
      block: 'start',
      scrollMode: 'if-needed',
      // boundary: document.querySelector('.table-head'),
      // boundary: '.pricing-table-wrapper',
    });
  };

  return (
    <Section sliceName="HeroWithCenteredText" className={`${className} ${styles.headerContent}`} containerClassName={styles.container}>
      {enableProductBackground && (
        <div className={styles.imageContainer}>
          <DebuggerExport className={styles.imageBottomLeft} />
          <FlagEvaluation className={styles.imageBottomRight} />
          <FlagSetting className={styles.imageTopLeft} />
          <PercentageRollout className={styles.imageTopRight} />
        </div>
      )}
      {/* // TEXT CONTENT */}
      {tagline?.text && <div dangerouslySetInnerHTML={{ __html: taglineHtml }} className={styles.tagline} />}
      {title?.text && <div dangerouslySetInnerHTML={{ __html: titleHtml }} className={styles.title} />}
      {subtitle?.text && <div dangerouslySetInnerHTML={{ __html: subtitleHtml }} className={styles.subtitle} />}
      {content?.text && <div dangerouslySetInnerHTML={{ __html: contentHtml }} className={styles.content} />}
      {/* // CTAS */}
      {(hasSecondary || hasPrimary || jumpToAnchorText || showModalButton) && (
        <div className={styles.ctaContainer}>
          {hasPrimary && (
            <Link className={`button ${styles.primaryCta}`} to={primaryLink.url}>
              {primaryText.text}
            </Link>
          )}
          {hasSecondary && (
            <Link className={styles.secondaryCta} withArrow to={secondaryLink.url}>
              {secondaryText.text}
            </Link>
          )}
          {showModalButton && <MarketoModalButton data={buttonData} />}
          {jumpToAnchorText && (
            <a className="button" onClick={jumpToAnchorOnClick} href={`#${jumpToAnchorText}`}>
              {primaryText.text}
            </a>
          )}
        </div>
      )}
      {isBottomImageAvailable && (
        <Image image={bottomImage} className={`${styles.bottomImage} ${narrowImageClass}`} />
      )}
    </Section>
  );
};

export default HeaderContent;
