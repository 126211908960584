import React from 'react';
import { StaticImage } from "gatsby-plugin-image";
import { MarketoForm } from 'components';
import * as styles from './styles.module.scss';

const NewsletterSignup = () => {
  return (
    <div className={styles.newsletterSignup}>
      <div className={styles.leftCol}>
        <div>
          <StaticImage
            src="../../../images/icons/icon-email.png"
            alt="Email icon"
            placeholder="none"
            className={styles.icon}
          />
        </div>
        <div>
          <h3>Inboxes love LaunchDarkly.</h3>
          <p className={`newsletter-footer-text`}>Make sure you get all the content, tips, and news you can use.</p>
        </div>
      </div>
      <div className={`${styles.inlineForm} newsletter-footer-mkto-form`}>
        <MarketoForm formId="2272" />
      </div>
    </div>
  )
}

export default NewsletterSignup;