// extracted by mini-css-extract-plugin
export var animateScrollIn = "styles-module--animateScrollIn--9eab6";
export var animateScrollOut = "styles-module--animateScrollOut--2530f";
export var colorSequence = "styles-module--colorSequence--9ee12";
export var diagonalFadeIn = "styles-module--diagonalFadeIn--ac371";
export var grow = "styles-module--grow--7591a";
export var growAndShrink = "styles-module--growAndShrink--b29b4";
export var growAndShrinkSubtle = "styles-module--growAndShrinkSubtle--37035";
export var moveBg = "styles-module--move-bg--446a9";
export var multiColor = "styles-module--multiColor--6c95a";
export var rotateLoop = "styles-module--rotateLoop--65d48";
export var spin = "styles-module--spin--00f95";
export var spinCounter = "styles-module--spinCounter--e9e0c";