import React, { useEffect } from 'react';
import Modal from 'react-modal';
import './styles.scss';

const modalStyles = {
  overlay: {
    backgroundColor: 'transparent',
    zIndex: '401',
  },
};

const ModalComponent = ({ modalOpen, toggleModal, children, appElement, hideButton, modalClass }) => {
  useEffect(() => {
    Modal.setAppElement(appElement || 'body');
  }, []);

  return (
    <Modal
      className={`placeholder ${modalClass}`}
      style={modalStyles}
      role="dialog"
      closeTimeoutMS={1000}
      isOpen={modalOpen}
      onRequestClose={toggleModal}
    >
      <div className="ld-modal main-modal is-active">
        <div className="ld-modal-background" onClick={toggleModal} />
        <div className="ld-modal-content ld-modal-content-wrapper">
          {!hideButton && (
            <button
              className="ld-modal-close-button"
              type="button"
              aria-label="close"
              tabIndex="0"
              onClick={toggleModal}
            />
          )}
          {children}
        </div>
      </div>
    </Modal>
  );
};

export default ModalComponent;
