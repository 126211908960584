import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import * as styles from './styles.module.scss';

const Spatter = ({ className = '' }) => (
  <div className={className}>
    <StaticImage className={styles.image} src="../../../images/yellow-nebula-spatter-alt.png" />
  </div>
);

export default Spatter;
