// extracted by mini-css-extract-plugin
export var animateScrollIn = "styles-module--animateScrollIn--165ff";
export var animateScrollOut = "styles-module--animateScrollOut--2bed6";
export var card = "styles-module--card--a062d";
export var cardContainer = "styles-module--cardContainer--04552";
export var cardContent = "styles-module--cardContent--c9564";
export var cardGrid = "styles-module--cardGrid--ea39d";
export var cardLink = "styles-module--cardLink--94344";
export var cardTitle = "styles-module--cardTitle--d84cd";
export var colorSequence = "styles-module--colorSequence--76d13";
export var diagonalFadeIn = "styles-module--diagonalFadeIn--bb106";
export var grow = "styles-module--grow--1bd76";
export var growAndShrink = "styles-module--growAndShrink--74934";
export var growAndShrinkSubtle = "styles-module--growAndShrinkSubtle--f216f";
export var icon = "styles-module--icon--bef32";
export var moveBg = "styles-module--move-bg--0c4bf";
export var oneLineIcon = "styles-module--oneLineIcon--dc4af";
export var redBubble = "styles-module--redBubble--58dac";
export var rotateLoop = "styles-module--rotateLoop--f69e8";
export var spin = "styles-module--spin--77f85";
export var spinCounter = "styles-module--spinCounter--dc4bc";
export var titleContainer = "styles-module--titleContainer--82a58";
export var twoColLayout = "styles-module--twoColLayout--3eb01";