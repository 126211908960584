// extracted by mini-css-extract-plugin
export var animateScrollIn = "styles-module--animateScrollIn--8def3";
export var animateScrollOut = "styles-module--animateScrollOut--ff39a";
export var colorSequence = "styles-module--colorSequence--8806c";
export var container = "styles-module--container--a5403";
export var diagonalFadeIn = "styles-module--diagonalFadeIn--28fb6";
export var grow = "styles-module--grow--cbd10";
export var growAndShrink = "styles-module--growAndShrink--59ce3";
export var growAndShrinkSubtle = "styles-module--growAndShrinkSubtle--cd3db";
export var header = "styles-module--header--6fe10";
export var moveBg = "styles-module--move-bg--a976a";
export var rotateLoop = "styles-module--rotateLoop--c0404";
export var spin = "styles-module--spin--265f1";
export var spinCounter = "styles-module--spinCounter--ec152";
export var staticNav = "styles-module--staticNav--79cf8";