// extracted by mini-css-extract-plugin
export var animateScrollIn = "styles-module--animateScrollIn--4f3f7";
export var animateScrollOut = "styles-module--animateScrollOut--9569c";
export var colorSequence = "styles-module--colorSequence--b586c";
export var diagonalFadeIn = "styles-module--diagonalFadeIn--89d40";
export var grow = "styles-module--grow--8ee3a";
export var growAndShrink = "styles-module--growAndShrink--a2118";
export var growAndShrinkSubtle = "styles-module--growAndShrinkSubtle--c654f";
export var moveBg = "styles-module--move-bg--19a4d";
export var rotateLoop = "styles-module--rotateLoop--992d2";
export var spin = "styles-module--spin--229ce";
export var spinCounter = "styles-module--spinCounter--0b60e";
export var withArrow = "styles-module--withArrow--0c295";