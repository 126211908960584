import React from 'react';
import { graphql } from 'gatsby';
import { Layout, Slice, PageSidebarNav, PageLeftbarNav, Link } from 'components';
import { ReactComponent as ArrowLeft } from 'images/icons/purple-arrow-left.svg';
import { ReactComponent as ArrowRight } from 'images/icons/purple-arrow-right.svg';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { linkResolver } from '../../.prismic/utils/linkResolver';
import * as styles from './page.module.scss';

const Page = ({ location, data }) => {
  const { page } = data;
  const { data: pageData, uid } = page;
  const {
    body: sliceData,
    meta_title: metaTitle,
    meta_description: metaDesc,
    ogImage,
    schema,
    page_title: pageTitle,
    page_tagline: pageTagline,
    previous_article: previousArticle,
    previous_article_title: previousArticleText,
    next_article: nextArticle,
    next_article_title: nextArticleTitle,
    page_nav_group: pageNavGroup,
  } = pageData;

  const seo = {
    title: metaTitle.text,
    desc: metaDesc.text,
    banner: ogImage && ogImage.url,
    schema: schema.text,
  };

  const cleandUid = uid.replace('.', '-');
  const pageClass = `${cleandUid}-page`;

  const pageTitleText = pageTitle?.text;
  const pageTaglineText = pageTagline?.text;

  const nextArticleLink = nextArticle?.url;
  const nextArticleTitleText = nextArticleTitle?.text;
  const previousArticleLink = previousArticle?.url;
  const previousArticleTitle = previousArticleText?.text;

  return (
    <Layout className="page" noBottomBg noTopBg location={location} seo={seo}>
      <div className={`${pageClass} page-container ${styles.pagesContainer}`}>
        <section>
          <div className={`container ${styles.innerContainer}`}>
            <div className={styles.pageMainContent}>
              <div className={styles.leftCol}>
                <PageLeftbarNav navGroup={pageNavGroup} location={location} pageTitle={pageTitleText} />
              </div>
              <div className={styles.mainContent}>
                <div className={styles.headerContainer}>
                  <h1>{pageTitleText}</h1>
                  <h3>{pageTaglineText}</h3>
                </div>
                {sliceData.map((slice) => {
                  return (
                    <Slice key={slice.id} data={slice}/>
                  )
                })}
                <div className={styles.nextPrevArticles}>
                  {previousArticleLink && (
                    <div className={`${styles.prevArticle} ${styles.articleLink}`}>
                      <span>Previous</span>
                      <Link to={previousArticleLink}>
                        <ArrowLeft />
                        {previousArticleTitle}
                      </Link>
                    </div>
                  )}
                  {!previousArticleLink && (
                    <span />
                  )}
                  {nextArticleLink && (
                    <div className={`${styles.nextArticle} ${styles.articleLink}`}>
                      <span>Next</span>
                      <Link to={nextArticleLink}>
                        {nextArticleTitleText}
                        <ArrowRight />
                      </Link>
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.sidebarNav}>
                <PageSidebarNav />
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

// previews
export default withPrismicPreview(Page);

export const pageQuery = graphql`
  query PageBySlug($uid: String!) {
    page: prismicPage(uid: { eq: $uid }, lang: {eq: "en-us"}) {
      id
      uid
      prismicId
      _previewable
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        ogImage: open_graph_image {
          url
        }
        schema {
          text
        }
        menu_order
        next_article {
          url
        }
        next_article_title {
          text
        }
        page_nav_group
        page_title {
          text
        }
        page_tagline {
          text
        }
        previous_article {
          url
        }
        previous_article_title {
          text
        }
        body {
          ...PageWysiwyg
          ...PageInlineQuote
          ...PageInlineTable
        }
      }
    }
  }
`;
