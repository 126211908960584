import React, { useState } from 'react';
import YouTube from 'react-youtube';
import { OnVisible } from 'components';
import './styles.scss';

const YouTubeVideo = ({ videoId, noAutoPlay, className }) => {
  const [youtubeApi, setYoutubeApi] = useState(false);

  if (!videoId) return null;

  const opts = {
    playerVars: {
      autoplay: 0,
      allowFullScreen: true,
      rel: 0,
      controls: 0,
      modestbranding: 1,
      loop: 1,
      showinfo: 0,
      frameBorder: 0,
    },
  };

  const onChange = (visibility) => {
    if (visibility && youtubeApi && !noAutoPlay) {
      youtubeApi.playVideo();
    }
  };

  const onReady = (event) => {
    // event.preventDefault()
    setYoutubeApi(event.target);
  };

  return (
    <OnVisible className={`youtube-video ${className || ''}`} onChange={onChange} bounce>
      <YouTube
        videoId={videoId}
        opts={opts}
        onReady={(event) => onReady(event)}
        className="youtube-embed"
        containerClassName="youtube-embed-container"
      />
    </OnVisible>
  );
};

export default YouTubeVideo;
