import React from 'react';
import { FooterLinks } from 'components';
import * as styles from './styles.module.scss';

const Footer = () => (
  <footer className={`footer ${styles.footer}`}>
    <div className="container">
      <FooterLinks />
    </div>
  </footer>
);

export default Footer;
