import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Link, Image, NewsletterSignup } from 'components';
import { ReactComponent as LDIcon } from 'images/launchdarkly.svg';
import * as styles from './styles.module.scss';

const FooterLinks = () => {
  const query = graphql`
    query FooterNavQuery {
      prismicSettings {
        data {
          footer_nav_links {
            link_text {
              text
            }
            link_url {
              url
            }
          }
          footer_sns_links {
            icon {
              url
              alt
            }
            sns_url {
              url
            }
          }
        }
      }
    }
  `;

  const data = useStaticQuery(query);
  const {
    prismicSettings: {
      data: { footer_nav_links: navLinks, footer_sns_links: snsLinks },
    },
  } = data;

  return (
    <>
      <NewsletterSignup />
      <div className={styles.footerTop}>
        <div className={styles.footerNote}>
          <span>Powered by</span>
          <LDIcon />
        </div>
        <div className={styles.linkGroupsContainer}>
          {navLinks.map((linkGroup) => {
            const {
              link_text: { text: linkText },
              link_url: { url: linkUrl },
            } = linkGroup;
            return (
              <div key={linkText}>
                <div className={styles.linkGroup}>
                  <Link ldExternal to={linkUrl}>{linkText}</Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className={styles.footerBottom}>
        <div className={styles.snsGroup}>
          {/* SNS */}
          {snsLinks.map((links) => {
            const {
              icon,
              sns_url: { url: linkUrl },
            } = links;
            return (
              <Link key={linkUrl} to={linkUrl}>
                <p className="sr-only">{icon.alt}</p>
                <Image image={icon} />
              </Link>
            );
          })}
        </div>
        <div className={styles.copyright}>&copy;{new Date().getFullYear()} Catamorphic Co.</div>
      </div>
    </>
  );
};

export default FooterLinks;
