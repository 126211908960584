// extracted by mini-css-extract-plugin
export var animateScrollIn = "styles-module--animateScrollIn--06e03";
export var animateScrollOut = "styles-module--animateScrollOut--5937d";
export var colorSequence = "styles-module--colorSequence--5a328";
export var diagonalFadeIn = "styles-module--diagonalFadeIn--4bc2f";
export var grow = "styles-module--grow--bc1ab";
export var growAndShrink = "styles-module--growAndShrink--309e9";
export var growAndShrinkSubtle = "styles-module--growAndShrinkSubtle--958d5";
export var moveBg = "styles-module--move-bg--8dafa";
export var rotateLoop = "styles-module--rotateLoop--bf112";
export var sidebarNav = "styles-module--sidebarNav--2a909";
export var sidebarNavGroup = "styles-module--sidebarNavGroup--2e52d";
export var spin = "styles-module--spin--37f83";
export var spinCounter = "styles-module--spinCounter--be0df";