import React from 'react';
import { Navigation } from 'components';
import * as styles from './styles.module.scss';

const Header = ({ staticNavbar, noNav }) => {
  const navbarPosition = staticNavbar ? styles.staticNav : '';
  return (
    <header className={`header-nav ${styles.header} ${navbarPosition}`}>
      <div>
        <Navigation noNav={noNav} />
      </div>
    </header>
  );
};

export default Header;
