import React from 'react';
import { graphql } from 'gatsby';
import { Layout, Slice, Particles } from 'components';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import './home.scss';

const Home = ({ location, data }) => {
  const {
    home: { data: pageData },
  } = data;

  const { body: sliceData, meta_title: metaTitle, meta_description: metaDesc, ogImage, schema } = pageData;

  const seo = {
    title: metaTitle.text,
    desc: metaDesc.text,
    banner: ogImage && ogImage.url,
    schema: schema.text,
  };

  return (
    <Layout seo={seo}>
      <div className="home">
        {sliceData.map((slice) => (
          <Slice key={slice.id} data={slice} />
        ))}
      </div>
    </Layout>
  );
};

export default withPrismicPreview(Home);

export const pageQuery = graphql`
  query HomeBySlug {
    home: prismicHome {
      id
      prismicId
      _previewable
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        ogImage: open_graph_image {
          url
        }
        schema {
          text
        }
        body {
          ...HomeHeroWithCenteredText
          ...HomeCardGrid
          ...HomeTwoColWithMedia
          ...BreakoutQuoteHomepageFragment
        }
      }
    }
  }
`;
