import React from 'react';
import { ReactComponent as GridTopCenter } from 'images/background/grid-top-center.svg';
import { ReactComponent as GridTopLeftSmall } from 'images/background/grid-top-left-small.svg';
import { ReactComponent as GridTopLeftLarge } from 'images/background/grid-top-left-large.svg';
import { ReactComponent as GridTopRight } from 'images/background/grid-top-right.svg';

import * as styles from './styles.module.scss';

const Grid = ({ className = '', backgroundGridType }) => {
  const gridTypes = {
    Center: { image: <GridTopCenter />, class: styles.topCenter },
    'Left Small': { image: <GridTopLeftSmall />, class: styles.topLeftSmall },
    'Left Large': { image: <GridTopLeftLarge />, class: styles.topLeftLarge },
    Right: { image: <GridTopRight />, class: styles.topRight },
    'Bottom Grid': { image: <GridTopRight />, class: styles.gridBottom },
    None: { image: null, class: null },
  };

  if (backgroundGridType) {
    return (
      <div className={`${styles.container} ${className} ${gridTypes[backgroundGridType]?.class}`}>
        {gridTypes[backgroundGridType]?.image}
      </div>
    );
  }

  return (
    <div className={`${styles.container} ${className} ${gridTypes['Left Large']?.class}`}>
      {gridTypes['Left Large']?.image}
    </div>
  );
};

export default Grid;
