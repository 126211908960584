import React from 'react';
import './styles.scss';

const WistiaVideo = ({ videoLink }) => {
  if (!videoLink) return null;
  return (
    <div className="wistia-video">
      <div className="wistia-video-responsive-wrapper">
        <iframe
          src={videoLink}
          title="Wistia Video"
          allowtransparency="true"
          frameBorder="0"
          scrolling="no"
          className="wistia_embed"
          name="wistia_embed"
          allowFullScreen
          mozallowfullscreen
          webkitallowfullscreen
          oallowfullscreen
          msallowfullscreen
          width="100%"
          height="100%"
        />
      </div>
    </div>
  );
};

export default WistiaVideo;
